var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"container",staticClass:"storeBox"},[_vm._l((_vm.storeList),function(item,index){return _c('div',{key:index,staticClass:"storeBox-box",on:{"click":function($event){$event.stopPropagation();return _vm.checked(item)}}},[_c('div',{staticClass:"store-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image),expression:"item.image"}],attrs:{"lazy-load":"true"}})]),_c('div',{staticClass:"store-cent-left"},[_c('div',{staticClass:"store-name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"store-address line1"},[_vm._v(" "+_vm._s(item.address)+_vm._s(", " + item.detailed_address)+" ")])]),_c('div',{staticClass:"row-right"},[_c('div',[_c('a',{staticClass:"store-phone",attrs:{"href":'tel:' + item.phone}},[_c('span',{staticClass:"iconfont icon-dadianhua01"})])]),_c('div',{staticClass:"store-distance",on:{"click":function($event){$event.stopPropagation();return _vm.showMaoLocation(item)}}},[(item.range)?_c('span',{staticClass:"addressTxt"},[_vm._v("距离"+_vm._s(item.range)+"千米")]):_c('span',{staticClass:"addressTxt"},[_vm._v("查看地图")]),_c('span',{staticClass:"iconfont icon-youjian"})])])])}),_c('Loading',{attrs:{"loaded":_vm.loaded,"loading":_vm.loading}})],2),_c('div',[(_vm.locationShow && !_vm.isWeixin)?_c('iframe',{ref:"geoPage",staticStyle:{"display":"none"},attrs:{"width":"0","height":"0","frameborder":"0","scrolling":"no","src":'https://apis.map.qq.com/tools/geolocation?key=' +
          _vm.mapKey +
          '&referer=myapp'}}):_vm._e()]),(_vm.mapShow)?_c('div',{staticClass:"geoPage"},[_c('iframe',{attrs:{"width":"100%","height":"100%","frameborder":"0","scrolling":"no","src":'https://apis.map.qq.com/uri/v1/geocoder?coord=' +
          _vm.system_store.latitude +
          ',' +
          _vm.system_store.longitude +
          '&referer=' +
          _vm.mapKey}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }